import { PermissionsType } from '@/common/permissionsConst';
import EnergyDispatchManagementEdit from './Edit';
import ControllerBasicInfo from './Edit/ControllerBasicInfo';
import ControllerMonitorNode from './Edit/ControllerMonitorNode';
import EnergyDispatchManagementList from './List';

const routes = [
  {
    path: '/energyAssetConstruction/energyTopologyManagement/energyDispatchManagement',
    permission: PermissionsType.P_UEDBASICSET,
    element: <EnergyDispatchManagementList />,
  },
  {
    path: '/energyAssetConstruction/energyTopologyManagement/energyDispatchManagement/edit/:id/*',
    permission: PermissionsType.P_UEDBASICINFOR,
    element: <EnergyDispatchManagementEdit />,
  },
 
  {
    path: '/energyAssetConstruction/energyTopologyManagement/energyDispatchManagement/basicInfo/:id',
    permission: PermissionsType.P_CONTROLLER_BASIC_SET,
    element: <ControllerBasicInfo />,
  },
  {
    path: '/energyAssetConstruction/energyTopologyManagement/energyDispatchManagement/nodeSet/:id/:dispatchGroupId/:dispatchUetStructId',
    permission: PermissionsType.P_CONTROLLER_NODE_SET,
    element: <ControllerMonitorNode />,
  },
  {
    path: '/energyAssetConstruction/energyTopologyManagement/energyDispatchManagement/detail/:id/*',
    permission: PermissionsType.P_UEDBASICINFOR,
    element: <EnergyDispatchManagementEdit detail />,
  },
];

export default routes;
