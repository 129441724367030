import { FilterFilled, SearchOutlined } from '@ant-design/icons';
import { Button, EllipsisSpan, Input, message, Table } from '@maxtropy/components';
import { apiV2StrategyRuleEditPost, V2StrategyRuleListPostResponse } from '@maxtropy/intelligent-dispath-apis-v2';
import { Divider, Popover, Space } from 'antd';
import { useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import { useParams } from 'react-router-dom';
export enum ButtonType {
  CLOSE = 0, // 触发关机
  POWER, // 降功率
  REMIND, // 提醒
}
export const buttonTypeDisplay = {
  [ButtonType.CLOSE]: '触发关机',
  [ButtonType.POWER]: '降功率',
  [ButtonType.REMIND]: '提醒',
};

type ListItem = Exclude<V2StrategyRuleListPostResponse['list'], undefined>[number];
interface Iprops {
  buttonTypeList: ButtonType[];
  list?: ListItem[];
  refresh?: () => void;
}
const TableList = ({ buttonTypeList, refresh, list }: Iprops) => {
  const { id } = useParams();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const divRef = useRef<HTMLDivElement | null>();
  const [loadingStatus, setLoadingStatus] = useState(false);
  const pageIndex = useRef<number>(0);
  const scrollTarget = useRef<any>();
  const [currentButtonType, setCurrentButtonType] = useState<ButtonType>();

  const { loading, runAsync: editApi } = useRequest(
    params => {
      return apiV2StrategyRuleEditPost(params);
    },
    {
      refreshDeps: [id],
      ready: !!id,
      manual: true,
    }
  );

  const rulesEdit = (fromType: ButtonType, toType: ButtonType, selectedRowKeys?: React.Key[]) => {
    setCurrentButtonType(toType);
    editApi({
      neoBsaId: id,
      alarmRuleIds: selectedRowKeys,
      fromType,
      toType,
    }).then(() => {
      message.success('操作成功!');
      refresh?.();
      setSelectedRowKeys([]);
      setSearchText(undefined);
    });
  };

  // 滚动加载
  const onScroll = (e: any) => {
    scrollTarget.current = e.target;
    if (e.target.scrollTop === e.target.scrollHeight - e.target.clientHeight) {
      if (loadingStatus) return;
      pageIndex.current++;
      setLoadingStatus(true);
      // setTempData([])
      // api请求
      setTimeout(() => {
        setLoadingStatus(false);
      }, 100);
    }
  };
  const [searchText, setSearchText] = useState<string>();

  const filterList = useMemo(() => {
    return list?.filter(
      item => !searchText || item?.name?.toLocaleLowerCase().includes(searchText.trim().toLocaleLowerCase())
    );
  }, [searchText, list]);
  const inputChange = (e: any) => {
    let searchText = e.target.value;
    setSearchText(searchText);
  };

  const tableColums = [
    {
      title: '序号',
      width: 60,
      dataIndex: 'alarmRuleId',
      ellipsis: { showTitle: true },
      render: (v: string, record: any, index: number) => <EllipsisSpan value={index + 1} />,
    },
    {
      title: <AlarmTableTitle onChange={inputChange} value={searchText} />,
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];
  return (
    <div>
      <Space size={24} style={{ marginBottom: 10 }}>
        {buttonTypeDisplay[buttonTypeList[0]]}
        <Space size={8}>
          <Button
            type="primary"
            loading={loading && currentButtonType === buttonTypeList[1]}
            disabled={!selectedRowKeys || !selectedRowKeys.length}
            onClick={() => rulesEdit?.(buttonTypeList[0], buttonTypeList[1], selectedRowKeys)}
          >
            设为{buttonTypeDisplay[buttonTypeList[1]]}
          </Button>
          <Button
            type="primary"
            loading={loading && currentButtonType === buttonTypeList[2]}
            disabled={!selectedRowKeys || !selectedRowKeys.length}
            onClick={() => rulesEdit?.(buttonTypeList[0], buttonTypeList[2], selectedRowKeys)}
          >
            设为{buttonTypeDisplay[buttonTypeList[2]]}
          </Button>
        </Space>

        {/* <Button
          onClick={() => {
            scrollTarget.current?.scrollTo({ top: 0 });
          }}
        >
          回到顶部
        </Button> */}
      </Space>
      <div
        ref={ele => {
          if (ele) {
            divRef.current = ele;
          }
        }}
        onScrollCapture={onScroll}
        style={{ border: '1px solid var(--mx-light-border-color)', height: 'calc(100vh - 360px)' }}
      >
        <Table
          pagination={false}
          rowKey="alarmRuleId"
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          scroll={{ y: 'calc(100vh - 411px)', scrollToFirstRowOnChange: true }}
          columns={tableColums}
          dataSource={filterList}
        />
      </div>
    </div>
  );
};
interface AlarmTableTitleProps {
  onChange?: (e: any) => void;

  value?: string;
}
const AlarmTableTitle = ({ onChange, value }: AlarmTableTitleProps) => {
  return (
    <div className={styles.title_filter_box}>
      报警名称
      <Input
        style={{ maxWidth: 200 }}
        placeholder="请输入报警名称关键字"
        value={value}
        onChange={e => onChange?.(e)}
        suffix={<SearchOutlined />}
      />
    </div>
  );
};

export default TableList;
