import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Checkbox, EllipsisSpan, Input, Key, Modal, Select, Table } from '@maxtropy/components';
import { Button as AntdButton } from 'antd';
import styles from './index.module.scss';
import { Space } from 'antd';
import { useRequest } from 'ahooks';
import {
  apiV2DevicePhysicalUnitListPost,
  V2DevicePhysicalUnitListPostResponse,
  apiV2MicrogridConfigurationDataPropertyConfigListPost,
  V2MicrogridConfigurationDataPropertyConfigListPostResponse,
  V2MicrogridConfigurationDataPropertyConfigEditPostRequest,
} from '@maxtropy/intelligent-dispath-apis-v2';
import { ColumnType } from 'antd/es/table';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { TimeDimensionEnm } from '@/pages/MicrogridConfOverview/components/TopoBox/type';

export type SaveDataItem = Exclude<
  Exclude<V2MicrogridConfigurationDataPropertyConfigEditPostRequest['list'], undefined>[number]['details'],
  undefined
>[number];

export type DataItem = Exclude<
  Exclude<V2MicrogridConfigurationDataPropertyConfigListPostResponse['list'], undefined>[number]['detail'],
  undefined
> & {
  propertyName: string;
  propertyId: number;
  measurementType: MeasurementType | undefined;
};

enum MeasurementType {
  CUMULATIVE = 1,
  INSTANTANEOUS = 2,
}

enum LimitationThreshold {
  ONE_MINUTES = 'ONE_MINUTES',
  THREE_MINUTES = 'THREE_MINUTES',
  FIFTEEN_MINUTES = 'FIFTEEN_MINUTES',
  ONE_HOUR = 'ONE_HOUR',
  FOUR_HOUR = 'FOUR_HOUR',
}

const LimitationThresholdLabel = {
  [LimitationThreshold.ONE_MINUTES]: '1分钟',
  [LimitationThreshold.THREE_MINUTES]: '3分钟',
  [LimitationThreshold.FIFTEEN_MINUTES]: '15分钟',
  [LimitationThreshold.ONE_HOUR]: '1小时',
  [LimitationThreshold.FOUR_HOUR]: '4小时',
};

const TimeDimensionEnum = {
  REAL_TIME_VALUE: 'REAL_TIME_VALUE',
  DAY: 'DAY',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
};

const TimeDimensionEnumLabel = {
  [TimeDimensionEnum.REAL_TIME_VALUE]: '实时值',
  [TimeDimensionEnum.DAY]: '日',
  [TimeDimensionEnum.MONTH]: '月',
  [TimeDimensionEnum.YEAR]: '年',
};

/*  拖拽相关  */
interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}

const RowContext = React.createContext<RowContextProps>({});

const DragHandle: React.FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <AntdButton
      type="text"
      size="small"
      icon={<MenuOutlined />}
      style={{ cursor: 'move' }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row: React.FC<RowProps> = props => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  const contextValue = useMemo<RowContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners]
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

interface Props {
  open: boolean;
  onCancel: () => void;
  onOk: (data: SaveDataItem[]) => void;
  propData:
    | {
        elementId: Key | undefined;
        element: any;
        uetStructId: Key | undefined;
        deviceId: Key | undefined;
        microgridConfId: Key | undefined;
      }
    | undefined;
  confirmLoading: boolean;
}

const DataPropertyModal: FC<Props> = props => {
  const { open, onCancel, onOk, propData, confirmLoading } = props;
  const [searchText, setSearchText] = useState('');
  const [tableData, setTableData] = useState<DataItem[]>([]);
  const [unitCache, setUnitCache] = useState<{ [key: string]: V2DevicePhysicalUnitListPostResponse['list'] }>({});

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setTableData(prevState => {
        const activeIndex = prevState.findIndex(record => record.propertyId === active?.id);
        const overIndex = prevState.findIndex(record => record.propertyId === over?.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  const { data: list } = useRequest(
    async () => {
      const res = await apiV2MicrogridConfigurationDataPropertyConfigListPost({
        elementId: propData?.elementId,
        element: propData?.element,
        uetStructId: propData?.uetStructId,
        deviceId: propData?.deviceId,
        microgridConfId: propData?.microgridConfId,
      });
      setTableData(
        res.list
          ?.filter(item => item.detail)
          .map(item => ({
            propertyName: item.propertyName!,
            propertyId: item.propertyId!,
            measurementType: item.measurementType,
            ...item.detail,
          }))
          .sort((a, b) => (a.index ?? 0) - (b.index ?? 0)) ?? []
      );
      return res.list;
    },
    {
      ready: open && !!propData,
      refreshDeps: [open, propData],
    }
  );

  const showList = list?.filter(item => item.propertyName?.includes(searchText));

  // 获取单位
  useEffect(() => {
    const toFetchIds = tableData.map(item => item.propertyId!).filter(id => !unitCache[id]);
    if (!toFetchIds.length) return;
    Promise.all(
      toFetchIds.map(id => {
        return apiV2DevicePhysicalUnitListPost({ id });
      })
    ).then(res => {
      const newUnitCache = { ...unitCache };
      res.forEach((item, index) => {
        newUnitCache[toFetchIds[index]] = item.list;
      });
      setUnitCache(newUnitCache);
    });
  }, [tableData]);
  // 请求单位之后，给table对应的数据属性赋予默认单位
  useEffect(() => {
    const newTableData = [...tableData];
    newTableData.forEach(item => {
      if (!item.unitId) {
        item.unitId = unitCache[item.propertyId]?.[0]?.id;
        item.generalName = unitCache[item.propertyId]?.[0]?.generalName;
      }
    });
    setTableData(newTableData);
  }, [unitCache]);

  const columns: ColumnType<DataItem>[] = [
    {
      title: '数据点',
      key: 'sort',
      ellipsis: { showTitle: true },
      render: (_, record) => (
        <>
          <DragHandle />
          <EllipsisSpan value={record.propertyName} />
        </>
      ),
    },
    {
      title: '单位',
      dataIndex: 'unitId',
      ellipsis: { showTitle: true },
      render: (v: number, record) => (
        <Select
          style={{ width: '100%' }}
          options={unitCache[record.propertyId]?.map(item => ({
            value: item.id,
            label: item.generalName,
          }))}
          value={v}
          onChange={v => {
            const newTableData = tableData.map(item => {
              if (item.propertyId === record.propertyId) {
                return {
                  ...item,
                  unitId: v,
                  generalName: unitCache[record.propertyId]?.find(unit => unit.id === v)?.generalName,
                };
              }
              return item;
            });
            setTableData(newTableData);
          }}
        ></Select>
      ),
    },
    {
      title: '数据时效性阈值',
      dataIndex: 'limitationThreshold',
      ellipsis: { showTitle: true },
      render: (v, record) => (
        <Select
          style={{ width: '100%' }}
          options={Object.keys(LimitationThreshold).map(key => ({
            value: key,
            label: LimitationThresholdLabel[key as keyof typeof LimitationThresholdLabel],
          }))}
          value={v}
          onChange={v => {
            const newTableData = tableData.map(item => {
              if (item.propertyId === record.propertyId) {
                return {
                  ...item,
                  limitationThreshold: v,
                };
              }
              return item;
            });
            setTableData(newTableData);
          }}
        ></Select>
      ),
    },
    {
      title: '时间维度',
      dataIndex: 'timeDimensionEnum',
      ellipsis: { showTitle: true },
      render: (v, record) => (
        <Select
          style={{ width: '100%' }}
          options={
            record.measurementType === MeasurementType.CUMULATIVE
              ? Object.keys(TimeDimensionEnum).map(key => ({
                  value: key,
                  label: TimeDimensionEnumLabel[key],
                }))
              : [
                  {
                    value: TimeDimensionEnm.REAL_TIME_VALUE,
                    label: TimeDimensionEnumLabel.REAL_TIME_VALUE,
                  },
                ]
          }
          value={v}
          onChange={v => {
            const newTableData = tableData.map(item => {
              if (item.propertyId === record.propertyId) {
                return {
                  ...item,
                  timeDimensionEnum: v,
                };
              }
              return item;
            });
            setTableData(newTableData);
          }}
        ></Select>
      ),
    },
    {
      title: '数据点名称',
      dataIndex: 'alias',
      ellipsis: { showTitle: true },
      render: (v: string, record) => (
        <Input
          maxLength={30}
          value={v}
          onChange={e => {
            const value = e.target.value;
            const newTableData = tableData.map(item => {
              if (item.propertyId === record.propertyId) {
                return {
                  ...item,
                  alias: value,
                };
              }
              return item;
            });
            setTableData(newTableData);
          }}
        />
      ),
    },
  ];

  const onCheckChange = (id: number, checked: boolean) => {
    if (checked) {
      const found = list?.find(item => item.propertyId === id);
      setTableData([
        ...tableData,
        {
          propertyId: found?.propertyId!,
          propertyName: found?.propertyName!,
          measurementType: found?.measurementType,
          limitationThreshold: LimitationThreshold.THREE_MINUTES,
          timeDimensionEnum: TimeDimensionEnum.REAL_TIME_VALUE,
          unitId: unitCache[id]?.[0]?.id, // 默认单位，给缓存的第一个。如果缓存没有，会在请求单位列表后，对该字段赋值。
          generalName: unitCache[id]?.[0]?.generalName,
        },
      ] as any);
    } else {
      setTableData(tableData.filter(item => item.propertyId !== id));
    }
  };

  const getCheckboxDisabled = (propertyId: number) => {
    const currentChecked = !!tableData.find(item => item.propertyId === propertyId);
    if (currentChecked) return false;
    if (tableData.length >= 15) return true;
  };

  return (
    <Modal
      title="选择/编辑数据属性"
      size="big"
      confirmLoading={confirmLoading}
      open={open}
      onCancel={onCancel}
      onOk={() => {
        onOk(
          tableData.map((item, index) => {
            return {
              ...item,
              index: index,
            } as SaveDataItem;
          })
        );
      }}
    >
      <div className={styles.modalContent}>
        <div className={styles.left}>
          <Input.Search
            placeholder="请输入名称"
            className={styles.search}
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          ></Input.Search>
          <Space direction="vertical" size={8} className={styles.checkListWrap}>
            {showList?.map((item, index) => {
              return (
                <Checkbox
                  key={index}
                  checked={!!tableData.find(tableDataItem => tableDataItem.propertyId === item.propertyId)}
                  disabled={getCheckboxDisabled(item.propertyId!)}
                  onChange={e => onCheckChange(item.propertyId!, e.target.checked)}
                >
                  {item.propertyName}
                </Checkbox>
              );
            })}
          </Space>
        </div>
        <div className={styles.divider} />
        <div className={styles.right}>
          <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext items={tableData.map(i => i.propertyId)} strategy={verticalListSortingStrategy}>
              <Table
                rowKey="propertyId"
                scroll={{
                  y: 490,
                }}
                components={{ body: { row: Row } }}
                columns={columns}
                dataSource={tableData}
              ></Table>
            </SortableContext>
          </DndContext>

          <div className={styles.footer}>
            最多选择 15 个数据点，已选择 <span style={{ color: 'var(--error-color)' }}>{tableData.length}</span>{' '}
            个，可按照先后顺序进行排名
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DataPropertyModal;
